<template>
  <div id="reportTable">
    <!-- DO NOT REMOVE WILL BREAK -->

    <div class="title">
      <h3 style="display: none;">{{ data.full_name }} {{ title }}</h3>
    </div>

    <!-- DO NOT REMOVE WILL BREAK -->

    <table>
      <tbody>
        <template v-if="tablePick === 'Entries'">
          <Entries
            :data="data"
            :title="title"
            :home="home"
            :config="config"
            :team="team"
            :season="season"
            :code="code"
            :showGender="showGender"
            class="entries-component"
            :show_info_web="show_info_web"
            :hide_score_card="hide_score_card"
          />
        </template>
        <template v-if="tablePick === 'Draw'">
          <Draw
            :data="data"
            :title="title"
            :home="home"
            :config="config"
            :team="team"
            :match="match"
            :season="season"
            :code="code"
            :showGender="showGender"
            :show_info_web="show_info_web"
            :hide_score_card="hide_score_card"
          />
        </template>
        <template v-if="tablePick === 'Score' || tablePick === 'Live'">
          <Score
            :data="data"
            :title="title"
            :home="home"
            :config="config"
            :team="team"
            :match="match"
            :season="season"
            :code="code"
            :scoreFound="scoreFound"
            :showGender="showGender"
            :is_qualifier="is_qualifier"
            :stableford="stableford"
            :show_info_web="show_info_web"
            :hide_score_card="hide_score_card"
          />
        </template>
        <template v-if="tablePick === 'Final'">
          <Results
            :data="data"
            :dataShowOOM="data.show_winnings"
            :dataHavePoints="data.have_points"
            :dataShowPointsName="data.show_points_name"
            :dataHaveOom="data.have_oom"
            :title="title"
            :home="home"
            :config="config"
            :team="team"
            :match="match"
            :season="season"
            :code="code"
            :showGender="showGender"
            :is_qualifier="is_qualifier"
            :stableford="stableford"
            :roundsplayed="data.rounds_played"
            :show_info_web="show_info_web"
            :hide_score_card="hide_score_card"
          />
        </template>
        <template v-for="(tabledata, index) in Eds">
          <tr
            @click.stop="rowClicked(index)"
            :key="index"
            :class="{ drawNoborder: tablePick === 'Draw' }"
          ></tr>
          <tr
            @click.stop="rowClicked(rowClicked === -1)"
            v-if="index === clickedRow"
            class="tr-kids"
            :key="index + Math.random()"
          >
            <td id="reportCard" colspan="11">
              <PlayerReportCard
                :data="playerCardData"
                :config="config"
                :actualRound="data.pft_round"
                :roundsPlayed="data.pft_round"
                :playerData="playerData"
                :playerDataTeam="playerDataTeam"
                :showGender="showGender"
                :season="season"
                :show_info_web="show_info_web"
                :hide_score_card="hide_score_card"
              />
            </td>
          </tr>
        </template>
        <template>
          <div
            class="LiveScoreMessblue"
            v-if="data.live_scoring_mess"
            :class="{ LiveScoreMess: data.play_suspended == 'Y' }"
          >
            <p class="LiveScoreMessPara">{{ data.live_scoring_mess }}</p>
          </div>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
// import apiCall from "@/services/api.js";
import {
  Entries,
  Draw,
  Score,
  PlayerReportCard,
  Results
} from "@ocs-software/vue-ocs";
export default {
  name: "reportTable",
  props: [
    "data",
    "previousData",
    "home",
    "title",
    "season",
    "code",
    "tour",
    "team",
    "config",
    "match",
    "dataShowOOM",
    "scoreFound",
    "showGender",
    "is_qualifier",
    "stableford",
    "show_info_web",
    "hide_score_card"
  ],
  components: { PlayerReportCard, Entries, Draw, Score, Results },
  data() {
    return {
      compare: [],
      up: "up",
      down: "down",
      scores: [],
      clickedRow: -1,
      cardID: [],
      playerCardData: [],
      win: "win"
    };
  },
  methods: {
    range: function(start, end) {
      if (this.roundsPlayed === 1) return this.roundsPlayed;
      else
        return Array(end - start + 1)
          .fill()
          .map((_, idx) => start + idx);
    },
    rowClicked: function(index) {
      if (this.clickedRow !== index) this.clickedRow = index;
      else this.clickedRow = -1;
    },
    playerCard: function(playerNo) {
      var playerData = {};
      // var cardID = event.target.getAttribute("title");
      console.log("playerData");
      console.log(playerData);
      var cardID = playerNo;
      console.log("cardID");
      console.log(cardID);
      for (var i of this.data.scores.scores_entry) {
        console.log("in scores");
        if (i.member_no == playerNo) {
          this.playerData = i;
          console.log("playerData 998");
          console.log(this.playerData);
          break;
        }
      }
      for (var t of this.data.team_scores.team_scores_entry) {
        console.log("in scores");
        if (t.member_no == playerNo) {
          this.playerDataTeam = t;
          console.log("playerData 998");
          console.log(this.playerDataTeam);
          break;
        }
      }
    }
  },
  computed: {
    tablePick: function(picked) {
      if (this.title === "Tournament Entries") picked = "Entries";
      else if (this.title.includes("Draw")) picked = "Draw";
      else if (
        (this.title.includes("Scores") && this.data.live_scoring === "N") ||
        (this.title.includes("Scoreboard") && this.data.live_scoring === "N")
      )
        picked = "Score";
      else if (
        (this.title.includes("Scores") && this.data.live_scoring === "Y") ||
        (this.title.includes("Scoreboard") && this.data.live_scoring === "Y")
      )
        picked = "Live";
      else if (this.title.includes("Final")) picked = "Final";
      else if (this.title.includes("Live")) picked = "Live";
      return picked;
    },
    roundsPlayed() {
      return parseInt(this.data.rounds_played);
    },
    Eds(reportData) {
      if (this.home === false) {
        if (this.title.includes("Final") || this.title.includes("Statistics"))
          reportData = this.data.result.result_entry;
        return reportData;
      } else {
        if (this.title.includes("Final") || this.title.includes("Statistics"))
          reportData = this.data.result.result_entry.slice(0, 6);
        return reportData;
      }
    }
  },
  watch: {
    Eds(newValue, oldValue) {
      newValue.forEach((value1, key1) => {
        oldValue.forEach((value2, key2) => {
          if (value1.id === value2.id) {
            if (key1 < key2) {
              value1.posChang = "up";
            } else if (key1 > key2) {
              value1.posChang = "down";
            }
          }
        });
      });

      console.log(newValue);
    },
    immediate: true,
    deep: true
  },
  filters: {
    lowercase: function(value) {
      if (!value) {
        return "";
      }
      return value.toLowerCase() + ".svg";
    }
  }
};
</script>

<style scoped>
p.LiveScoreMessPara {
  color: #fff;
  padding-top: 15px;
}
.LiveScoreMess {
  background: red;
  text-align: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 99;
  margin: auto;
  left: 0;
  right: 0;
}
.LiveScoreMessblue {
  background: blue;
  text-align: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 99;
  margin: auto;
  left: 0;
  right: 0;
}
tr.drawNoborder {
  border-top: none !important;
}
span.multiDot-size-report {
  float: right;
  font-size: 60px !important;
  line-height: 0px !important;
  position: relative;
  top: 12px;
}
.B {
  color: #333333;
}
.Y {
  color: #d8db22;
}
.C {
  color: #1e90ff;
}
.R {
  color: #bb0000;
}
.G {
  color: #3c9933;
}
.O {
  color: #e07020;
}
span.FinishedCap {
  color: #000;
}
.starStart {
  color: #000;
}
.NameLeft {
  text-align: left;
  z-index: 9;
}
tr.drawOnly {
  border-top: 0px !important;
}

td > a {
  color: #000 !important;
}
.FullListingBtn {
  text-align: center;
  display: block;
  margin: auto;
  background: #f2af15;
  border: none;
  color: #fff;
  border-radius: 0;
  padding: 5px 20px;
  width: 200px;
  margin-top: 1.5em;
  margin-bottom: 0.5em;
}
.FullListingBtn > a {
  color: #fff !important;
  text-decoration: none;
}
.FullListingBtn:hover {
  text-align: center;
  display: block;
  margin: auto;
  background: #214232;
  border: none;
  color: #fff;
  border-radius: 0;
  padding: 5px 20px;
  width: 200px;
  margin-top: 1.5em;
  margin-bottom: 0.5em;
}
.title {
  text-align: center;
  background-color: #00433e;
  color: white;
}
.title > h3 {
  padding-top: 8px;
  margin-bottom: 0px;
  font-size: 24px;
}
.title > p {
  font-size: 16px !important;
  margin-bottom: 0;
}
th {
  position: sticky;
  top: 0;
  background-color: #006737;
  vertical-align: bottom;
  border-bottom: 0px solid #ddd;
  font-weight: bold;
  color: white;
  font-size: 1rem;
  text-transform: capitalize;
  position: sticky;
  position: -webkit-sticky;
  top: -1px;
  color: #ffffff !important;
  text-align: center;
  padding: 0.75rem;
}

.win {
  background-color: #02b2dd !important;
  color: black;
}
.catD {
  background-color: #006737;
  color: #fff;
  font-weight: 600;
  font-size: 12pt;
}
.drawName {
  font-size: 14px !important;
}
table {
  width: 100%;
}

thead > tr {
  color: white;
}
td:first-child {
  text-align: center;
}
th:first-child {
  text-align: center;
}
th:nth-child(3) {
  text-align: center;
}
td:nth-child(3) {
  text-align: center;
}
.odd {
  background-color: white !important;
}
.even {
  background-color: #dfe1e6 !important;
}
tr:nth-child(even) {
  /* background-color: #dfe1e6; */
  /* background-color: rgba(0, 0, 0, 0.05); */
  border-top: 1px solid #ebebeb;
}
tr:nth-child(odd) {
  /* background-color: #dfe1e6; */
  /* background-color: rgba(0, 0, 0, 0.05); */
  border-top: 1px solid #ebebeb;
}
td.posnum {
  padding: 5px;
  padding-top: 4px;
  padding-bottom: 4px;
  display: block;
  max-width: 58px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2px;
  margin-bottom: 0px;
  color: black;
  font-weight: bold;
}
.drawPos {
  max-width: 100% !important;
  text-align: -webkit-center !important;
  margin-top: 0px !important;
}
td {
  padding: 8px;
  line-height: 1.42857143;
  vertical-align: top;
  border-top: 0px solid #ebebeb;
  border-right: none !important;
  cursor: pointer;
  font-size: 15px;
  text-align: center;
}

td.posnum > span {
  padding: 5px;
  display: block;
  max-width: 58px;
}
.noPos > span {
  color: transparent;
  padding: 5px;
  display: block;
  max-width: 58px;
}
.flag {
  width: 30px;
  box-shadow: 0 0 3px #555;
}
.up {
  color: #a52a2a;
}
.down {
  color: #1e90ff;
}
.text-down {
  background: transparent;
  text-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
  animation: glow-red 2s infinite;
  animation-iteration-count: 6;
}

@keyframes glow-red {
  to {
    text-shadow: 0 0 10px rgba(255, 82, 82, 0.7);
  }
}

.text-up {
  background: transparent;
  text-shadow: 0 0 0 0 rgba(51, 217, 178, 1);
  animation: glow-green 2s infinite;
  animation-iteration-count: 6;
}

@keyframes glow-green {
  to {
    text-shadow: 0 0 10px rgba(51, 217, 178, 0.7);
  }
}
.cut {
  padding-top: 6px !important;
  padding-right: 6px !important;
  padding-bottom: 6px !important;
  font-size: 19px;
  vertical-align: middle;
  padding-left: 8px !important;
  background: #aa0003;
  color: white;
}
@media only screen and (max-width: 768px) {
  td {
    font-size: 13px !important;
  }
  th {
    font-size: 13px;
  }
  #reportCard {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
</style>

<style scoped src="@/css/entries.css"></style>
<style scoped src="@/css/draw.css"></style>
<style src="@/css/scoresOnly.css"></style>
<style src="@/css/teamscoresOnly.css"></style>
<style src="@/css/hbh.css"></style>
<style src="@/css/results.css"></style>
<style src="@/css/orginalReportCard.css"></style>
<style src="@/css/playerReportCard.css"></style>
