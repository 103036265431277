<template>
    <div>
        <div
        v-if="side === false"
        style="text-align:center; max-height: 150px; padding-top:30px; padding-bottom: 30px;"
        :class="{ white: white === true }"
        >
        <!-- All Home Desktop Banners -->

        <!-- Top -->

            <div v-if="page === 'home' && adNum === 'top' && mob === false" class="topSecOne">
                <script
                type="application/javascript"
                async
                src="https://securepubads.g.doubleclick.net/tag/js/gpt.js"
                ></script>
                <script type="application/javascript">
                    window.googletag = window.googletag || {cmd: []};
                    googletag.cmd.push(function() {
                        googletag.defineSlot('/21903684810/SST_web_728x90', [728, 90], 'div-gpt-ad-1624438368833-0').addService(googletag.pubads());
                        googletag.pubads().enableSingleRequest();
                        googletag.enableServices();
                        googletag.pubads().collapseEmptyDivs();
                    });
                </script>
                <div id='div-gpt-ad-1624438368833-0' style='min-width: 728px; min-height: 90px;'>
                    <script type="application/javascript">
                    googletag.cmd.push(function() { googletag.display('div-gpt-ad-1624438368833-0'); });
                    </script>
                </div>
            </div>

        <!-- second -->

            <div v-if="page === 'home' && adNum === 'second' && mob === false" class="topSecOne">
                <script
                type="application/javascript"
                async
                src="https://securepubads.g.doubleclick.net/tag/js/gpt.js"
                ></script>
                <script type="application/javascript">
                    window.googletag = window.googletag || {cmd: []};
                    googletag.cmd.push(function() {
                    googletag.defineSlot('/21903684810/sst_web_300x100', [300, 100], 'div-gpt-ad-1624439572368-0').addService(googletag.pubads());
                    googletag.pubads().enableSingleRequest();
                    googletag.enableServices();
                    googletag.pubads().collapseEmptyDivs();
                    });
                </script>
                <div id='div-gpt-ad-1624439572368-0' style='min-width: 300px; min-height: 100px; height:90px;'>
                    <script type="application/javascript">
                    googletag.cmd.push(function() { googletag.display('div-gpt-ad-1624439572368-0'); });
                    </script>
                </div>
            </div>

        <!-- third -->

            <div v-if="page === 'home' && adNum === 'third' && mob === false">
                <script
                type="application/javascript"
                async
                src="https://securepubads.g.doubleclick.net/tag/js/gpt.js"
                ></script>
                <script type="application/javascript">
                window.googletag = window.googletag || { cmd: [] };
                googletag.cmd.push(function() {
                    googletag
                    .defineSlot(
                        "/21903684810/webad720x300",
                        [720, 300],
                        "div-gpt-ad-1624444708995-0"
                    )
                    .addService(googletag.pubads());
                    googletag.pubads().enableSingleRequest();
                    googletag.enableServices();
                });
                </script>
                <!-- /22264165340/LET-Homepage-BannerThree-728x90 -->
                <div
                id="div-gpt-ad-1624444708995-0"
                style="min-width: 728px; min-height: 90px; margin-bottom: 10em; margin-top: 2em;"
                >
                    <script type="application/javascript">
                        googletag.cmd.push(function() {
                        googletag.display("div-gpt-ad-1624444708995-0");
                        googletag.pubads().refresh();
                        });
                    </script>
                </div>
            </div>

        <!-- Footer -->

            <div v-if="page === 'footer' && adNum === 'fourth' && mob === false">
                <script
                type="application/javascript"
                async
                src="https://securepubads.g.doubleclick.net/tag/js/gpt.js"
                ></script>
                <script type="application/javascript">
                window.googletag = window.googletag || { cmd: [] };
                googletag.cmd.push(function() {
                    googletag
                    .defineSlot(
                        "/21903684810/webSST-720x300",
                        [720, 300],
                        "div-gpt-ad-1624448513962-0"
                    )
                    .addService(googletag.pubads());
                    googletag.pubads().enableSingleRequest();
                    googletag.enableServices();
                });
                </script>
                <!-- /22264165340/LET-Homepage-BannerThree-728x90 -->
                <div
                id="div-gpt-ad-1624448513962-0"
                style="min-width: 728px; min-height: 90px; margin-bottom: 17.5em;"
                >
                    <script type="application/javascript">
                        googletag.cmd.push(function() {
                        googletag.display("div-gpt-ad-1624448513962-0");
                        googletag.pubads().refresh();
                        });
                    </script>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: "ads",
  props: ["page", "adNum", "mob", "white", 'side'],
};
</script>

<style scoped>
::v-deep .middleAd-above-vid {
    display: inline-block;
    width: 100%;
}
.topSecOne {
    margin-top: -3em;
    margin-bottom: 30px;
}
.white {
    background-color: white;
}
::v-deep iframe#google_ads_iframe_\/21903684810\/sst_web_300x100_0 {
    height: 100px;
}
::v-deep div#google_ads_iframe_\/21903684810\/webSST-720x300_0__container__ {
    height: 90px;
}
</style>
