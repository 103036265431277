/*
 ** event-bus.js
 */

import io from "socket.io-client";

class socketServe {
  openSocket(user, password) {
    const options = {
      path: "/tms/" + "sst" + "/socket.io",
      transports: ["websocket", "polling"],
      port: ["3001"],
    };
    var socket = io("https://ocs-africa.com", options);

    socket.on("connect", () => {
      console.log(socket.id);
    });

    socket.emit("auth", "sst " + user + " '" + password + "' WEB");
    socket.on("respauth", function(respstatus) {
      respstatus = JSON.parse(respstatus);
      console.log(respstatus);
      if (respstatus.status == "OK") {
        console.log("OK");
        sessionStorage.playerID = respstatus.member;
        sessionStorage.playerName = respstatus.name;
        sessionStorage.status = respstatus.status;
        sessionStorage.password = password;
      }
      if (respstatus.status == "FAILED") {
        //alert("Failed to log in: " + respstatus.message);
        console.log("Fail");
        sessionStorage.status = respstatus.status;
      }
    });
  }

  enterSocket(user, password, text, code, id, fee) {
    const options = {
      path: "/tms/" + "sst" + "/socket.io",
      transports: ["websocket", "polling"],
      port: ["3001"],
    };
    var socket = io("https://ocs-africa.com", options);

    socket.on("connect", () => {
      console.log(socket.id);
    });

    socket.emit("auth", "sst " + user + " '" + password + "' WEB");
    socket.on("respauth", function(respstatus) {
      respstatus = JSON.parse(respstatus);
      console.log(respstatus);
      if (respstatus.status == "OK") {
        console.log("OK", user, fee, id, code, text);
        sessionStorage.playerID = respstatus.member;
        sessionStorage.playerName = respstatus.name;
        sessionStorage.status = respstatus.status;
        sessionStorage.password = password;
      }
      if (respstatus.status == "FAILED") {
        //alert("Failed to log in: " + respstatus.message);
        console.log("Fail");
        sessionStorage.status = respstatus.status;
      }
    });
  }
}
export default new socketServe();

"sst " + "ALH001" + " '" + "oc$$portOCT2017" + "' WEB";
