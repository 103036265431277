<template>
    <div>
        <b-row>
          <b-col lg="12" md="12" sm="12" class="" order="2" order-sm="2" order-md="2" order-lg="1">
            <b-carousel
              class="imageSlider carousel-sync"
              id="carousel-slide"
              controls
              interval="5000"
              carousel-sync
            >
              <b-carousel-slide>
                <b-row>
                  <b-col lg="7" md="12" sm="12" class="" order="2" order-sm="2" order-md="2" order-lg="1">
                    <b-card-body class="hoz" title="Featured Player Blog" :sub-title="allposts[0].title.rendered">
                      <b-card-text>
                        <span class='hozText' v-html="allposts[0].excerpt.rendered"></span>
                        <b-button :to="'/' + allposts[0].slug" class="postBtn">View Full Article</b-button>
                      </b-card-text>
                      <div class="ScoreSlider">
                        <span class="Number">1 / 3</span>
                      </div>
                    </b-card-body>
                  </b-col>
                  <b-col lg="5" md="12" sm="12" order="1" order-sm="1" order-md="1" order-lg="2">
                    <b-card-img
                      class="hozImg"
                      :src="allposts[0].better_featured_image.source_url"
                      :config="config"
                    ></b-card-img>
                  </b-col>
                </b-row>
              </b-carousel-slide>
              <b-carousel-slide>
                <b-row>
                  <b-col lg="7" md="12" sm="12" class="" order="2" order-sm="2" order-md="2" order-lg="1">
                    <b-card-body class="hoz" title="Featured Player Blog" :sub-title="allposts[1].title.rendered">
                      <b-card-text>
                        <span class='hozText' v-html="allposts[1].excerpt.rendered"></span>
                        <b-button :to="'/' + allposts[1].slug" class="postBtn">View Full Article</b-button>
                      </b-card-text>
                      <div class="ScoreSlider">
                        <span class="Number">2 / 3</span>
                      </div>
                    </b-card-body>
                  </b-col>
                  <b-col lg="5" md="12" sm="12" order="1" order-sm="1" order-md="1" order-lg="2">
                    <b-card-img
                      class="hozImg"
                      :src="allposts[1].better_featured_image.source_url"
                      :config="config"
                    ></b-card-img>
                  </b-col>
                </b-row>
              </b-carousel-slide>
              <b-carousel-slide>
                <b-row>
                  <b-col lg="7" md="12" sm="12" class="" order="2" order-sm="2" order-md="2" order-lg="1">
                    <b-card-body class="hoz" title="Featured Player Blog" :sub-title="allposts[2].title.rendered">
                      <b-card-text>
                        <span class='hozText' v-html="allposts[2].excerpt.rendered"></span>
                        <b-button :to="'/' + allposts[2].slug" class="postBtn">View Full Article</b-button>
                      </b-card-text>
                      <div class="ScoreSlider">
                        <span class="Number">3 / 3</span>
                      </div>
                    </b-card-body>
                  </b-col>
                  <b-col lg="5" md="12" sm="12" order="1" order-sm="1" order-md="1" order-lg="2">
                    <b-card-img
                      class="hozImg"
                      :src="allposts[2].better_featured_image.source_url"
                      :config="config"
                    ></b-card-img>
                  </b-col>
                </b-row>
              </b-carousel-slide>
            </b-carousel>
          </b-col>
          <b-col lg="5" md="12" sm="12" order="1" order-sm="1" order-md="1" order-lg="2">
            <b-carousel
              class="imageSlider carousel-sync"
              id="carousel-fade-slide"
              fade
              interval="5000"
              carousel-sync
            >
              <!-- <b-carousel-slide>
                <b-card-img
                  class="hozImg"
                  :src="allposts[0].better_featured_image.source_url"
                  :config="config"
                ></b-card-img>
              </b-carousel-slide> -->
              <!-- <b-carousel-slide>
                <b-card-img
                  class="hozImg"
                  :src="allposts[1].better_featured_image.source_url"
                  :config="config"
                ></b-card-img>
              </b-carousel-slide>
              <b-carousel-slide>
                <b-card-img
                  class="hozImg"
                  :src="allposts[2].better_featured_image.source_url"
                  :config="config"
                ></b-card-img>
              </b-carousel-slide> -->
            </b-carousel>
          </b-col>
        </b-row>

        <!-- <Carousel>
        </Carousel> -->

    </div>
</template>

<script>
import apiCall from "@/services/api.js";
// import Carousel from "@/components/image-slide.vue";
export default {
  name: "Score News Homepage",
  props: ["cat", 'feat', 'config'],
  components: {
    // Carousel
  },
  data() {
    return {
      allposts: [],
      postsRow: "posts",
      postImg: "postImg",
    };
  },
  methods: {
    newLabel: function(label) {
      if (this.cat === "8") label = "News";
      return label;
    },
  },
  created() {
    const json = "wp/v2/posts?categories=" + this.cat;
    apiCall
      .wpInfo(json)
      .then(({ data }) => {
        this.allposts = data;
        this.loading = false;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
};
</script>

<style scoped>
.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
img.hozImg.card-img {
    background: white;
    padding: 7px;
    margin-top: 25px;
}
.Number {
    font-weight: bold;
}
.ScoreSlider {
    color: #6C6C6C;
    font-size: 14px;
    font-weight: bold;
    margin-top: 25px;
}
span.scoreHomePos {
    padding: 1.25rem;
    color: #F2AF14;
    font-weight: bold;
    font-size: 25px;
}
::v-deep .carousel-control-prev-icon {
    background-image: url(https://updated.sunshinetour.com/wp-content/uploads/2022/09/Group7-1.png);
    z-index: 999;
}
::v-deep .carousel-control-next-icon {
    background-image: url(https://updated.sunshinetour.com/wp-content/uploads/2022/09/Group6.png);
    z-index: 999;
}
::v-deep .carousel-control-prev-icon:hover {
    background-image: url(https://updated.sunshinetour.com/wp-content/uploads/2022/09/Group7_hover.png);
    z-index: 999;
}
::v-deep .carousel-control-next-icon:hover {
    background-image: url(https://updated.sunshinetour.com/wp-content/uploads/2022/09/Group6._hover.png);
    z-index: 999;
}
::v-deep .carousel-control-prev, .carousel-control-next {
  position: initial!important;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: inline-block;
  align-items: center;
  justify-content: center;
  width: 3%;
  color: #fff;
  text-align: center;
  opacity: 1;
  transition: opacity 0.15s ease;
  margin-top: 36px;
  margin-right: 15px;
  margin-left: 18px;
  top: 40px;
}
::v-deep .carousel-control-next, .carousel-control-prev {
    position: initial!important;
    top: 0;
    bottom: 0;
    z-index: 1;
    display: inline;
    align-items: center;
    justify-content: center;
    width: 15%;
    color: #fff;
    text-align: center;
    opacity: 1;
    transition: opacity 0.15s ease;
}
::v-deep img.img-fluid.w-100.d-block {
    height: 0px;
}
::v-deep .carousel-caption {
    position: initial;
    right: 0%;
    bottom: 0px;
    left: 0%;
    z-index: 10;
    padding-top: 0px;
    padding-bottom: 0px;
    color: #fff;
    text-align: left;
}
article.card.NewsPageImage > img {
  max-height: 100%;
  min-height: auto;
}
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #F2F2F2;
  background-clip: border-box;
  border: 0px solid rgba(0, 0, 0, 0.125);
  border-radius: 0px;
}
.hozCol.scorePage.col-md-7 {
  padding-right: 50px;
}
.thescoreTop {
  border-radius: 20px!important;
}
.scorePage > div {
  padding: 0rem;
}
img.card-img-top {
  /* max-height: 232px; */
  min-height: 180px;
  border-radius: 0px;
  object-fit: cover;
  object-position: top;
}
.theScoreCard {
  border-radius: 0px!important;
}
.card-text:last-child {
  margin-bottom: 0;
  color: #000;
}
.img-fluid {
  max-width: 100%;
  height: auto;
  width: 100%;
}
span.postText {
  font-size: 12pt;
}
h1.postsTitle {
  font-size: 20pt;
  padding-top: 15px;
  padding-bottom: 15px;
  white-space: nowrap;
  width: 350px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.overflowImage {
  overflow: hidden;
  height: 278px;
}

.card-title {
  font-weight: 100;
  letter-spacing: -0.5px;
  color: #9aa5ac;
  margin-top: -2px;
  margin-bottom: -5px;
  font-size: 11px;
  color: #f2af15;
  text-transform: capitalize;
}
.feat > .card-body > .card-subtitle {
  font-size: 23pt !important;
}
.card-subtitle {
  color: #6C6C6C !important;
  font-weight: bold;
  font-size: 15px;
  margin-top: 15px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
 
}
.viewAll {
  position: relative;
  top: 1em;
  color: #000;
  font-weight: 600 !important;
  font-size: 14px;
  padding-left: 15px;
}
.noRightPad {
  padding-right: 0;
  padding-left: 5px;
}
.bottomPad {
  padding-bottom: 20px;
}
.nav-link {
  padding: 0;
}

.mobShow {
  display: none;
}

.card-text {
  padding-top: 7px;
}
.postBtn {
  margin: auto;
  background: #006737;
  border: none;
  color: #fff!important;
  border-radius: 0;
  width: 100%;
  font-size: 11px;
  text-transform: capitalize;
  border: 1px solid #E0E0E0;
  width: 200px;
  /* height: 35px; */
  font-weight: bold;
  padding-top: 7px;
  line-height: 18px;
}
.postBtn:hover{
  background-color: #204232;
  color: #fff!important;
}
.hoz > h4 {
  color: #F2AF14;
  font-size: 13px;
  line-height: 25px;
  font-weight: 100;
  margin-top: 24px;
  margin-bottom: 15px;
}
.hozCol {
  padding: 1.25rem;
}
.hozImg {
  border-radius: 0px;
}
.hozTop {
  border-radius: 0px;
}
::v-deep .hozText>p{
  margin-bottom: 15px;
  color: #6C6C6C;
  font-size: 13px;
  width: 500px;
}
@media only screen and (max-width: 480px) {
  #mobilePost {
    padding-top: 2%;
    padding-bottom: 2%;
  }
  .feat {
    height: 485px !important;
  }
  .featMob {
    height: 350px !important;
  }
  img.card-img-top {
    max-height: inherit;
    min-height: 263px!important;
    border-radius: 0px;
  }
}
@media only screen and (max-width: 1165px) {
  #mobilePost:last-child {
    padding-right: 15px;
    margin-top: 2em;
  }
  div#mobilePost {
    margin-top: 1em;
  }
}

@media only screen and (max-width: 990px) {
  ::v-deep .hozText>p {
    margin-bottom: 15px; 
    color: #6C6C6C;
    font-size: 13px;
    width: 100%!important;
  }
  .homePageNews {
    flex-wrap: nowrap;
    overflow: scroll;
  }
  img.card-img-top {
    min-height: 219px;
    border-radius: 0px;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: top;
    object-position: top;
  }
  .postBtn {
    margin: auto;
    background: #006737;
    border: none;
    color: #fff!important;
    border-radius: 0;
    width: 100%;
    font-size: 11px;
    text-transform: capitalize;
    border: 1px solid #E0E0E0;
    width: 100%;
    /* height: 35px; */
    font-weight: bold;
    padding-top: 7px;
    line-height: 18px;
  }
}

@media only screen and (max-width: 768px) {
  .card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #F2F2F2;
    background-clip: border-box;
    border: 0px solid rgba(0, 0, 0, 0.125);
    border-radius: 0px;
  }
  .hozImg {
    border-radius: 0px;
  }
  .hozCol.scorePage.col-md-7 {
    padding-right: 30px;
    padding-left: 30px;
  }
  img.card-img-top {
    max-height: initial;
    /* min-height: auto; */
    border-radius: 0px;
  }
  .featMob {
    height: 621px;
  }
  .noRightPad {
    padding: 15px;
  }
  .mobShow {
    display: block;
  }
}

</style>
