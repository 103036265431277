var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-row',{staticClass:"Desk"},[_c('b-col',{staticClass:"mobCol"},[_c('div',{staticClass:"dataTitle"},[_vm._v(_vm._s(_vm.currentOomTitle))]),_c('a',{staticClass:"playertopSizes",attrs:{"href":'/playerprofile/' + _vm.currentOomList[0].playerno}},[_c('b-img',{staticClass:"oomTopImg",attrs:{"src":_vm.DEFIMAGESAMS3 + 'sst' + '/' + 'media/photos/' +
            _vm.currentOomList[0].playerno +
            '.jpg'}})],1),_c('a',{staticClass:"playerLink",attrs:{"href":'/playerprofile/' + _vm.currentOomList[0].playerno}},[_c('b-row',{staticClass:"bottomOom"},[_c('b-col',{attrs:{"lg":"12"}},[_c('span',{staticClass:"homePos"},[_c('span',{staticClass:"innerPos"},[_vm._v(_vm._s(_vm.currentOomList[0].pos))])])]),_c('b-col',{attrs:{"lg":"12"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","lg":"2","md":"2","sm":"2"}},[_c('b-img',{staticClass:"playerFlag",attrs:{"src":_vm._f("lowercase")((_vm.config.VUE_APP_FLAG_URL + _vm.currentOomList[0].nationality))}})],1),_c('b-col',{attrs:{"cols":"12","lg":"10","md":"10","sm":"10"}},[_c('span',{staticClass:"infoBlock"},[_c('p',{staticClass:"topOomName"},[_vm._v(" "+_vm._s(_vm.currentOomList[0].name)+" ")]),_c('p',{staticClass:"topOomName oomValue"},[_vm._v(" "+_vm._s(_vm.currentOomList[0].oom_value)+" ")]),_c('p',{staticClass:"tournsPlayed"},[_c('span',[_vm._v("Tournaments: ")]),_vm._v(_vm._s(_vm.currentOomList[0].tournaments_played)+" ")])])])],1)],1)],1)],1)]),_c('b-col',{staticClass:"mobCol"},[[_c('div',{staticClass:"dataTitle"},[_vm._v(_vm._s(_vm.rookieTitle))]),_c('a',{staticClass:"playertopSizes",attrs:{"href":'/playerprofile/' + _vm.rookieList[0].playerno}},[_c('b-img',{staticClass:"oomTopImg",attrs:{"src":_vm.DEFIMAGESAMS3 + 'sst' + '/' + 'media/photos/' +
              _vm.rookieList[0].playerno +
              '.jpg'}})],1),_c('a',{staticClass:"playerLink",attrs:{"href":'/playerprofile/' + _vm.rookieList[0].playerno}},[_c('b-row',{staticClass:"bottomOom"},[_c('b-col',{attrs:{"lg":"12"}},[_c('span',{staticClass:"homePos"},[_c('span',{staticClass:"innerPos"},[_vm._v(_vm._s(_vm.rookieList[0].pos))])])]),_c('b-col',{attrs:{"lg":"12"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","lg":"2","md":"2","sm":"2"}},[_c('b-img',{staticClass:"playerFlag",attrs:{"src":_vm._f("lowercase")((_vm.config.VUE_APP_FLAG_URL + _vm.rookieList[0].nationality))}})],1),_c('b-col',{attrs:{"cols":"12","lg":"10","md":"10","sm":"10"}},[_c('span',{staticClass:"infoBlock"},[_c('p',{staticClass:"topOomName"},[_vm._v(" "+_vm._s(_vm.rookieList[0].name)+" ")]),_c('p',{staticClass:"topOomName oomValue"},[_vm._v(" "+_vm._s(_vm.rookieList[0].oom_value)+" ")]),_c('p',{staticClass:"tournsPlayed"},[_c('span',[_vm._v("Tournaments: ")]),_vm._v(_vm._s(_vm.rookieList[0].tournaments_played)+" ")])])])],1)],1)],1)],1)]],2),_c('b-col',{staticClass:"mobCol"},[_c('div',{staticClass:"dataTitle"},[_vm._v(_vm._s(_vm.statsTitle))]),_c('a',{staticClass:"playertopSizes",attrs:{"href":'/playerprofile/' + _vm.statsLists[0].playerno}},[_c('b-img',{staticClass:"oomTopImg",attrs:{"src":_vm.DEFIMAGESAMS3 + 'sst' + '/' + 'media/photos/' +
            _vm.statsLists[0].playerno +
            '.jpg'}})],1),_c('a',{staticClass:"playerLink",attrs:{"href":'/playerprofile/' + _vm.statsLists[0].playerno}},[_c('b-row',{staticClass:"bottomOom"},[_c('b-col',{attrs:{"lg":"12"}},[_c('span',{staticClass:"homePos"},[_c('span',{staticClass:"innerPos"},[_vm._v(_vm._s(_vm.statsLists[0].pos))])])]),_c('b-col',{attrs:{"lg":"12"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","lg":"2","md":"2","sm":"2"}},[_c('b-img',{staticClass:"playerFlag",attrs:{"src":_vm._f("lowercase")((_vm.config.VUE_APP_FLAG_URL + _vm.statsLists[0].nationality))}})],1),_c('b-col',{attrs:{"cols":"12","lg":"10","md":"10","sm":"10"}},[_c('span',{staticClass:"infoBlock"},[_c('p',{staticClass:"topOomName"},[_vm._v(" "+_vm._s(_vm.statsLists[0].name)+" ")]),_c('p',{staticClass:"topOomName oomValue"},[_vm._v(" "+_vm._s(_vm.statsLists[0].oom_value)+" ")]),_c('p',{staticClass:"tournsPlayed"},[_c('span',[_vm._v("Tournaments: ")]),_vm._v(_vm._s(_vm.statsLists[0].tournaments_played)+" ")])])])],1)],1)],1)],1)])],1),_c('b-row',{staticClass:"Mob"},[_c('b-col',{staticClass:"mobCol",attrs:{"cols":"12","sm":"12"}},[_c('div',{staticClass:"dataTitle"},[_vm._v(_vm._s(_vm.currentOomTitle))]),_c('a',{staticClass:"playertopSizes",attrs:{"href":'/playerprofile/' + _vm.currentOomList[0].playerno}},[_c('b-img',{staticClass:"oomTopImg",attrs:{"src":_vm.DEFIMAGESAMS3 + 'sst' + '/' + 'media/photos/' +
            _vm.currentOomList[0].playerno +
            '.jpg'}})],1),_c('b-row',{staticClass:"bottomOom"},[_c('b-col',{attrs:{"lg":"12"}},[_c('span',{staticClass:"homePos"},[_c('span',{staticClass:"innerPos"},[_vm._v(_vm._s(_vm.currentOomList[0].pos))])])]),_c('b-col',{attrs:{"lg":"12"}},[_c('b-row',[_c('b-col',{staticClass:"mobFlag",attrs:{"lg":"2","md":"2","sm":"2"}},[_c('b-img',{staticClass:"playerFlag",attrs:{"src":_vm._f("lowercase")((_vm.config.VUE_APP_FLAG_URL + _vm.currentOomList[0].nationality))}})],1),_c('b-col',{staticClass:"mobInfo",attrs:{"lg":"10","md":"10","sm":"10"}},[_c('span',{staticClass:"infoBlock"},[_c('p',{staticClass:"topOomName"},[_vm._v(" "+_vm._s(_vm.currentOomList[0].name)+" ")]),_c('p',{staticClass:"topOomName oomValue"},[_vm._v(" "+_vm._s(_vm.currentOomList[0].oom_value)+" ")]),_c('p',{staticClass:"tournsPlayed"},[_c('span',[_vm._v("Tournaments: ")]),_vm._v(_vm._s(_vm.currentOomList[0].tournaments_played)+" ")])])])],1)],1)],1)],1),[_c('b-col',{staticClass:"mobCol",attrs:{"cols":"12","sm":"12"}},[_c('div',{staticClass:"dataTitle"},[_vm._v(_vm._s(_vm.rookieTitle))]),_c('a',{staticClass:"playertopSizes",attrs:{"href":'/playerprofile/' + _vm.rookieList[0].playerno}},[_c('b-img',{staticClass:"oomTopImg",attrs:{"src":_vm.DEFIMAGESAMS3 + 'sst' + '/' + 'media/photos/' +
              _vm.rookieList[0].playerno +
              '.jpg'}})],1),_c('b-row',{staticClass:"bottomOom"},[_c('b-col',{attrs:{"lg":"12"}},[_c('span',{staticClass:"homePos"},[_c('span',{staticClass:"innerPos"},[_vm._v(_vm._s(_vm.rookieList[0].pos))])])]),_c('b-col',{attrs:{"lg":"12"}},[_c('b-row',[_c('b-col',{staticClass:"mobFlag",attrs:{"lg":"2","md":"2","sm":"2"}},[_c('b-img',{staticClass:"playerFlag",attrs:{"src":_vm._f("lowercase")((_vm.config.VUE_APP_FLAG_URL + _vm.rookieList[0].nationality))}})],1),_c('b-col',{staticClass:"mobInfo",attrs:{"lg":"10","md":"10","sm":"10"}},[_c('span',{staticClass:"infoBlock"},[_c('p',{staticClass:"topOomName"},[_vm._v(" "+_vm._s(_vm.rookieList[0].name)+" ")]),_c('p',{staticClass:"topOomName oomValue"},[_vm._v(" "+_vm._s(_vm.rookieList[0].oom_value)+" ")]),_c('p',{staticClass:"tournsPlayed"},[_c('span',[_vm._v("Tournaments: ")]),_vm._v(_vm._s(_vm.rookieList[0].tournaments_played)+" ")])])])],1)],1)],1)],1)],_c('b-col',{staticClass:"mobCol",attrs:{"cols":"12","sm":"12"}},[_c('div',{staticClass:"dataTitle"},[_vm._v(_vm._s(_vm.statsTitle))]),_c('a',{staticClass:"playertopSizes",attrs:{"href":'/playerprofile/' + _vm.statsLists[0].playerno}},[_c('b-img',{staticClass:"oomTopImg",attrs:{"src":_vm.DEFIMAGESAMS3 + 'sst' + '/' + 'media/photos/' +
            _vm.statsLists[0].playerno +
            '.jpg'}})],1),_c('b-row',{staticClass:"bottomOom"},[_c('b-col',{attrs:{"lg":"12"}},[_c('span',{staticClass:"homePos"},[_c('span',{staticClass:"innerPos"},[_vm._v(_vm._s(_vm.statsLists[0].pos))])])]),_c('b-col',{attrs:{"lg":"12"}},[_c('b-row',[_c('b-col',{staticClass:"mobFlag",attrs:{"lg":"2","md":"2","sm":"2"}},[_c('b-img',{staticClass:"playerFlag",attrs:{"src":_vm._f("lowercase")((_vm.config.VUE_APP_FLAG_URL + _vm.statsLists[0].nationality))}})],1),_c('b-col',{staticClass:"mobInfo",attrs:{"lg":"10","md":"10","sm":"10"}},[_c('span',{staticClass:"infoBlock"},[_c('p',{staticClass:"topOomName"},[_vm._v(" "+_vm._s(_vm.statsLists[0].name)+" ")]),_c('p',{staticClass:"topOomName oomValue"},[_vm._v(" "+_vm._s(_vm.statsLists[0].oom_value)+" ")]),_c('p',{staticClass:"tournsPlayed"},[_c('span',[_vm._v("Tournaments: ")]),_vm._v(_vm._s(_vm.statsLists[0].tournaments_played)+" ")])])])],1)],1)],1)],1)],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }